// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.buttons {
  width: 100%;
  margin-bottom: 5px;
  @include flex(row, space-between, center);
  flex-wrap: wrap;
  @include for-tablet-up {
    @include flex(row, center, center);
  }
}

.button {
  margin: 5px 1%;
  text-decoration: none;
  text-align: center;
  padding: 10px 14px;
  border: 2px $tertiary-color solid;
  border-radius: 6px;
  background-color: $tertiary-color;
  color: $white;
  font-weight: bold;

  &:hover,
  &:focus {
    background-color: $secondary-color;
    border-color: $secondary-color;
  }

  &--secondary {
    background-color: $white;
    color: $tertiary-color;

    &:hover,
    &:focus {
      color: $secondary-color;
      border-color: $secondary-color;
      background-color: $white;
    }
  }
}
