// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'small': 320px,
  'medium': 768px,
  'large': 1024px,
) !default;

$white: hsla(0%, 0%, 100%, 1);
$light-color: hsla(356%, 71%, 83%, 1);
$primary-color: hsla(356%, 100%, 35%, 1);
$secondary-color: hsla(356%, 65%, 62%, 1);
$tertiary-color: hsla(356%, 100%, 24%, 1);
$dark-color: hsla(356%, 96%, 19%, 1);