// -----------------------------------------------------------------------------
// Basic typography style for copy text.
// -----------------------------------------------------------------------------

body {
  font-family: 'Roboto', 'Helvetica', sans-serif;

  @include for-desktop-up {
    font-size: 1.2rem;
  }
}
