nav {
  button {
    background: none;
    border: none;
    color: rgba(255, 255, 255, 0.6);

    &:hover,
    &:focus {
      color: rgba(255, 255, 255, 1);
      cursor: pointer;
    }
  }
}
