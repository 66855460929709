.project {
  padding-bottom: 10px;
  border-bottom: 2px solid $tertiary-color;

  @include for-desktop-up {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 25% 75%;
    align-items: center;
  }

  b {
    color: $tertiary-color;
  }
  &__header {
    @include flex(column, center, center);

    @include for-desktop-up {
      grid-row: 1;
    }
  }
  &__title {
    color: $tertiary-color;
  }
  &__details {
    padding: 20px 2%;
    line-height: 1.4rem;

    @include for-desktop-up {
      grid-row: 2;
    }
  }
  &__description {
    text-align: justify;
    padding-bottom: 20px;
  }
  &__role {
    padding-bottom: 10px;
  }
  &__tech {
    width: 100%;
    @include flex(row, space-between, center);
    flex-wrap: wrap;

    @include for-tablet-up {
      @include flex(row, center, center);
    }
  }
  &__thumbnail {
    margin: 20px 0;

    img {
      width: 100%;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
        0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    @include for-desktop-up {
      grid-row: 1 / span 2;
      padding: 20px;
    }
  }
}
