.content-wrapper {
  @include flex(column, center, center);
}

.content {
  margin: 10px 3% 50px 3%;
  padding: 0 2%;
  text-align: justify;
  max-width: 1200px;
  @include flex(column, center, center);

  h1 {
    color: $tertiary-color;
  }
}
