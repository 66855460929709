// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.header {
  padding: 10px 3%;
  background-color: $dark-color;
  color: $white;
  line-height: 24px;
  display: grid;
  grid-template-rows: 30px 30px 30px 1fr;
  grid-template-columns: 25% auto;
  justify-items: center;
  align-items: center;

  @include for-tablet-up {
    grid-template-columns: 120px auto 200px;
    grid-template-rows: repeat(2, 1fr);
    height: 150px;
  }

  @include for-desktop-up {
    grid-template-columns: 120px auto 400px 200px;
    height: 100px;
  }
}

.avatar {
  margin: 10px 2%;
  width: 80px;
  border-radius: 50%;
  grid-row: 1 / span 3;
}

.nav {
  grid-column: 1 / span 2;
  width: 100%;

  @include for-tablet-up {
    grid-column: 1 / span 3;
    font-size: 1.4rem;
  }

  @include for-desktop-up {
    grid-column: 3;
    grid-row: 1 / span 2;
  }

  ul {
    width: 100%;
    @include flex(row, space-evenly, center);
    padding: 0;

    @include for-desktop-up {
      @include flex(row, space-evenly, flex-start);
    }

    li {
      list-style: none;
      color: rgba($white, 0.6);
      cursor: pointer;

      &:hover {
        color: rgba($white, 1);
      }
    }
  }
}

.name {
  font-size: 1.2rem;
  margin: 0;
  grid-row: 1;
  font-weight: bold;

  @include for-tablet-up {
    font-size: 1.6rem;
    grid-row: 1;
    justify-self: left;
    align-self: flex-end;
  }
}

.title {
  margin: 0;
  font-size: 0.8rem;
  grid-row: 2;

  @include for-tablet-up {
    font-size: 1rem;
    grid-row: 2;
    justify-self: left;
    align-self: flex-start;
    padding-top: 5px;
  }
}

.socialicons {
  font-size: 1.4rem;
  margin: 2% 0;
  width: 100%;
  grid-row: 3;
  @include flex(row, space-evenly);

  @include for-tablet-up {
    grid-row: 1 / span 2;
    grid-column: 3;
  }

  @include for-desktop-up {
    grid-column: 4;
  }

  a {
    color: $white;
    @include flex();
    opacity: 0.6;
    padding: 0 2% 5px 2%;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }
}
